<template>
    <div class="contanier">
        <div v-if="!checkIsLogin">
            <div class="haeder-nav flex-d">
                <div class="header-logo" v-if="siteSettings()">
                    <router-link to="/">
                        <img :src="siteSettings().aws_url + siteSettings().storage_path.domain_image + siteSettings().logo" alt="">
                    </router-link>
                </div>
                <div class="user-login index-view">
                    <div class="mobile-login flex-d">
                        <router-link to="/sign-up" type="button" class="btn btn-login-1 btn-register" v-if="siteSettings()?.business_type == 2">
                            {{ translatedLangData('register', 'Register') }}
                        </router-link>
                        <button @click="showAgeConfirmationModal()" type="button" class="btn btn-login-1">
                            {{ translatedLangData('login', 'Login') }} <i class="fa-solid fa-right-to-bracket"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="checkIsLogin">
            <div class="haeder-nav flex-d">
                <div class="top-side-search">
                    <div class="header-logo">
                        <router-link to="/" v-if="!checkIsLogin">
                            <img :src="siteSettings().aws_url + siteSettings().storage_path.domain_image + siteSettings().logo" alt="">
                        </router-link>
                        <div class="after-login-bets-buttons">
                            <a title="Setting" class="open-bets" href="#openBets" data-bs-toggle="modal">
                                <img src="@/assets/images/open-bet.svg" alt="">
                                &nbsp;{{ translatedLangData('bets', 'Bets') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="user-login">
                    <div class="main-exposure ng-star-inserted">
                        <div class="dropdown drop-side">
                            <button class="btn btn-secondary dropdown-toggle drop-menu" type="button"
                                data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
                                aria-controls="offcanvasExample">
                                <i class="fa-solid fa-user"></i>{{ translatedLangData('my-account', 'My Account') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="balance">
                    <div class="header-deposit-wallet-sec" v-if="siteSettings()?.business_type == 2">
                        <a @click="getWalletGetway()" href="Javascript:void(0);" class="header-deposit-wallet">
                            <img src="@/assets/images/wallet-deposit-icon.svg" alt="">
                        </a>
                    </div>

                    <div class="Myaccount-after-login">
                        <div v-if="showBalance" class="mo_user-blnc">
                            <ul v-if="siteSettings()?.business_type == 1">
                                <li>
                                    <a href="Javascript:void(0);"> {{ translatedLangData('main-pti', 'Main PTI') }} 
                                        <b v-if="userDataFunc()">{{ userDataFunc().balance }}</b>
                                    </a>
                                </li>
                                <li>
                                    <a class="exp-topcount" data-bs-target="#mymarketModal" data-bs-toggle="modal" @click="callForMyMarket()">
                                        {{ translatedLangData('exposure','Exposure')}}
                                        <span v-if="userDataFunc()" class="exp">
                                            ({{ Math.abs(userDataFunc().expl ?? 0).toFixed(2) }})
                                        </span>
                                    </a>
                                </li>
                            </ul>
                            <ul v-else>
                                <li>
                                    <a href="Javascript:void(0);"> {{ translatedLangData('main-pti', 'Main PTI') }}
                                        <b v-if="userDataFunc()"> {{ userDataFunc().balance }}</b>
                                    </a>
                                </li>
                                <li>
                                    <a data-bs-target="#mymarketModal" data-bs-toggle="modal" class="exp-topcount" @click="callForMyMarket()">
                                        {{ translatedLangData('exposure', 'Exposure') }} 
                                        <span v-if="userDataFunc()">
                                            (<span class="exp">{{ Math.abs(userDataFunc().expl ?? 0).toFixed(2) }}</span>)
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div v-if="showLoading" class="loadingBalance">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div class="reload-banking-btn">
                            <div class="login-refresh-MAcnt-btn">
                                <button type="button" @click="refreshBalance()">
                                    <img src="@/assets/images/reload.png" alt="">
                                </button>
                            </div>
                            <div class="acc-setting-btn">
                                <button type="button" @click="goToNewSetting">
                                    <img src="@/assets/images/settings.png" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <headerMarquee v-if="marqueeShow()"></headerMarquee>
    </div>
    <OpenBetsModal v-if="checkIsLoginForOpenBets" />
    <AgeConfLoginModal></AgeConfLoginModal>
    <MyMarket ref="myMarketFun"></MyMarket>
</template>
<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../../services/api';
import * as apiName from '../../services/urls';
import headerMarquee from '../../../shared/components/marquee-news/MarqueeNews.vue';
import OpenBetsModal from '@/modules/bets/views/OpenBetsModal.vue';
import { Modal } from "bootstrap";
import AgeConfLoginModal from '@/shared/components/modal/AgeConfirmationLoginModal.vue';
import MyMarket from '../modal/MyMarket.vue';

export default {
    name: 'Header',
    inject:['translatedLangData'],
    data() {
        return {
            userData: null,
            showElement: false,
            showRef: false,
            showBalance: true,
            showLoading: false
        }
    },
    mounted() {
        this.userData = this.$store?.getters?.stateUser;
    },
    computed: {
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        checkIsLoginForOpenBets() {
            return setTimeout(() => {
                return this.$store?.getters?.isAuthenticated;
            }, 1000);
        }
    },
    components: {
        OpenBetsModal,
        headerMarquee,
        AgeConfLoginModal,
        MyMarket
    },
    methods: {
        toggleMenu() {
            this.showElement = true
        },
        betInfo() {
            this.showRef = !this.showRef
        },
        goToNewSetting() {
            this.$router.push({ name: 'stake-setting'});
        },
        userDataFunc() {
            return this.$store?.getters?.stateUser;
        },
        logoutBtnClick() {
            this.logoutServiceCall();
        },
        logoutServiceCall() {
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                            this.$emit('success-modal', response?.data?.message);
                            localStorage.removeItem('is_offer');
                            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie));
                            this.$store.dispatch('resetAllState');
                            this.$router.push({ name: 'Home' });
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        getWalletGetway() {
            this.$router.push({name: 'wallet-gateway'});
        },
        refreshBalance() {
            this.showBalance = false;
            this.showLoading = true;
            
            setTimeout(() => {
                this.showBalance = true;
                this.showLoading = false;
            }, 500);
        },
        showAgeConfirmationModal() {
            const modal = new Modal(document.getElementById('ageconfirmationModal'));
            modal.show();
        },
        marqueeShow() {
            let routePath = this.$route.path;
             return routePath === '/';
        },
        callForMyMarket() {
            this.$refs.myMarketFun.getMyMarketsServiceCall('sport');
        }
    }
}
</script>
<style scoped>
a:hover {
    color: #ffffff;
}
</style>